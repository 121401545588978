<template>
    <v-text-field
        v-if="editableMode"
        autofocus
        persistent-hint
        v-model="shortId"
        append-icon="save"
        v-on:click:append="saveUserProvidedShortId"
        dense
        outlined></v-text-field>
    <div v-else class="d-flex align-center">
        <span class="secondary--text caption">Short name:</span>
        <v-tooltip left class="mr-1">
            <template v-slot:activator="{ on }">
                <v-icon small v-on="on">help_outlined</v-icon>
            </template>
            <span>A short-name identifies the object in a database or the file-system.</span>
        </v-tooltip>
        {{ shortId }}
        <v-icon v-if="false" @click="editableMode = true">edit</v-icon>
    </div>
</template>

<script>
import { makeShortId } from '../utils'
export default {
    name: 'ShortIdInput',
    data() {
        return {
            editableMode: false,
            shortId: ''
        }
    },
    props: {
        longId: String,
        disabledEdit: { type: Boolean, default: false }
    },
    methods: {
        saveUserProvidedShortId: function () {
            this.shortId = makeShortId(this.shortId)
            this.editableMode = false
        }
    },
    watch: {
        longId: function (nextValue, prevValue) {
            this.shortId = makeShortId(nextValue)
        },
        shortId: function (nextValue, prevValue) {
            this.$emit('input', { nextValue })
        }
    },
    mounted() {
        this.shortId = makeShortId(this.$props.longId)
        this.$emit('input', { nextValue: this.shortId })
    }
}
</script>
